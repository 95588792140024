import React, { Component } from "react";
import {
  Form,
  Button,
  DatePicker,
  Row,
  Col,
  Input,
  TimePicker,
  Checkbox,
} from "antd";
import * as dayjs from "dayjs";
import AddEditOperationsPhotos from "./add-edit-operations-photos";
import { AimOutlined } from "@ant-design/icons";
import MapLatLng from "../common/map-latlng";

class AddMounting extends Component {
  state = { mapVisible: false, mapKey: 1, latlng: "", gps_info: false };

  formRef = React.createRef();

  onFinish = (values) => {
    this.props.handleAddMounting(values);
  };

  disabledDate = (current) => {
    return current && current > dayjs();
  };

  setData = () => {
    let data = this?.props?.editRecord;

    let mounting_date =
      this?.props?.modalAction === "edit-mounting"
        ? data?.mounting_date
        : this?.props?.modalAction === "edit-monitoring"
        ? data?.monitoring_performed_date
        : data?.unmounting_date;

    this.formRef.current.setFieldsValue({
      mounting_date: mounting_date && dayjs(mounting_date),
    });
  };

  setCurrentRecord = (cRecord) => {
    if (
      this?.props?.editRecord &&
      cRecord?.lat_long?.x &&
      cRecord?.lat_long?.y
    ) {
      this.formRef.current.setFieldsValue({
        lat_long_x: cRecord?.lat_long?.x ? cRecord?.lat_long?.x : "",
        lat_long_y: cRecord?.lat_long?.y ? cRecord?.lat_long?.y : "",
        gps_date:
          cRecord?.gps_date &&
          cRecord?.gps_date !== "0001-01-01T00:00:00" &&
          dayjs(cRecord?.gps_date),
        gps_time:
          cRecord?.gps_time &&
          cRecord?.gps_time !== "00:00:00" &&
          dayjs(cRecord?.gps_time, "HH:mm:ss"),
      });

      this.setState({
        latlng: {
          lat: cRecord?.lat_long?.x || "",
          lng: cRecord?.lat_long?.y || "",
        },
        gps_info: true,
      });
    }
  };

  handleMap = () => {
    this.setState({ mapVisible: true, mapKey: this.state.mapKey + 1 });
  };

  setLatLong = (latlng) => {
    this.setState({ latlng });
    this.formRef.current.setFieldsValue({
      lat_long_x: latlng.lat,
      lat_long_y: latlng.lng,
    });
  };

  onChangeCheckBox = (e) => {
    this.setState({ gps_info: e.target.checked });
  };

  componentDidMount() {
    (this?.props?.modalAction === "edit-mounting" ||
      this?.props?.modalAction === "edit-unmounting-photo" ||
      this?.props?.modalAction === "edit-monitoring") &&
      this.setData();
  }

  render() {
    const { modalTitle, modalAction, page, selectedRows } = this.props;
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const { mapVisible, mapKey, latlng, gps_info } = this.state;

    const enable_gps = this?.props?.configuration_details?.enable_gps;

    return (
      <React.Fragment>
        <Form
          name="operation-modal"
          {...layout}
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            name="mounting_date"
            label={
              page === "pending-unmounting-photo"
                ? "Unmounting Date"
                : page === "monitoring"
                ? "Monitoring Date"
                : "Mounting Date"
            }
          >
            <DatePicker
              disabled={
                this?.props?.modalAction === "edit-mounting" ? true : false
              }
              inputReadOnly={true}
              disabledDate={this.disabledDate}
            />
          </Form.Item>

          {(this.props.selectedRowKeys.length === 1 ||
            modalAction === "edit-mounting" ||
            modalAction === "edit-unmounting-photo" ||
            modalAction === "edit-monitoring") && (
            <AddEditOperationsPhotos
              selectedId={
                modalAction !== "edit-mounting"
                  ? modalAction !== "edit-unmounting-photo"
                    ? modalAction !== "edit-monitoring"
                      ? this?.props?.selectedRowKeys[0]
                      : this?.props?.editRecord?.operation_monitoring_id
                    : this?.props?.editRecord?.operation_id
                  : this?.props?.editRecord?.operation_id
              }
              selectedRows={selectedRows[0]}
              modalAction={modalAction}
              operation_monitoring_id={
                this?.props?.editRecord?.operation_id || 0
              }
              setCurrentRecord={this.setCurrentRecord}
            />
          )}
          {(modalAction === "add-mounting" ||
            modalAction === "add-unmounting-photo" ||
            modalAction === "add-monitoring" ||
            modalAction === "edit-mounting" ||
            modalAction === "edit-unmounting-photo" ||
            modalAction === "edit-monitoring") && (
            <div style={{ marginTop: "24px" }}>
              {enable_gps && (
                <Row gutter={24} style={{ marginBottom: "24px" }}>
                  <Col span={24}>
                    <Checkbox
                      name="gps_info"
                      onChange={this.onChangeCheckBox}
                      checked={gps_info}
                    >
                      Set GPS Info
                    </Checkbox>
                  </Col>
                </Row>
              )}
              {enable_gps && gps_info && (
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      label="Latitude"
                      name="lat_long_x"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Latitude !",
                        },
                      ]}
                    >
                      <Input placeholder="Latitude" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Longitude"
                      name="lat_long_y"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Longitude !",
                        },
                      ]}
                    >
                      <Input placeholder="Longitude" style={{ width: "74%" }} />
                    </Form.Item>
                    <Button
                      onClick={this.handleMap}
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "12px",
                      }}
                      icon={<AimOutlined />}
                      className="lat-long-icon"
                    />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="gps_date"
                      label="GPS Date"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter GPS Date !",
                        },
                      ]}
                    >
                      <DatePicker inputReadOnly={true} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="gps_time"
                      label="GPS Time"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter GPS Time !",
                        },
                      ]}
                    >
                      <TimePicker inputReadOnly={true} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          )}

          <Form.Item
            wrapperCol={{ offset: 8 }}
            style={{ marginTop: "16px", marginBottom: "0px" }}
            className="model-btns"
          >
            <Button type="primary" htmlType="submit">
              {modalTitle}
            </Button>
          </Form.Item>
        </Form>

        {mapVisible && (
          <MapLatLng
            latlng={latlng}
            setLatLong={this.setLatLong}
            key={mapKey}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AddMounting;
