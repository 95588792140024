import * as JSZip from "jszip";
import { getImageRelayUrl } from "../../services/ajax-requester";
import { getImageBlobData } from "../../helpers/image-helper";

export const exportCampaignToZip = (
  reportName,
  inventory_details,
  configuration,
  callBack
) => {
  let zip = new JSZip();

  let urls = [];
  let index = 1;

  let inventories = inventory_details || [];

  if (reportName === "Mounting Report") {
    inventories = inventories.map((x) => ({
      ...x,
      ha_images: x?.mounting_photos,
    }));
  }

  if (reportName === "Monitoring Report") {
    inventories = inventories.map((x) => ({
      ...x,
      ha_images: x?.monitoring_photos,
    }));
  }

  if (reportName === "Unmounting Report") {
    inventories = inventories.map((x) => ({
      ...x,
      ha_images: x?.unmounting_photos,
    }));
  }

  inventories
    .filter((inventory) => inventory.ha_images?.length > 0)
    .forEach((inventory, inventoryIndex) => {
      inventory.ha_images.forEach((image, imageIndex) => {
        try {
          urls.push({
            url: getImageRelayUrl(
              image?.is_gps && image?.gps_photo_data_url
                ? image?.gps_photo_data_url
                : image?.data_url
            ),
            name: `${inventory.sr}) ${inventory.inventory_code} - ${
              inventory.district
            }, ${inventory.city} - ${inventory.area} - ${
              inventory.location
            } - ${inventory.size}${
              inventory.quantity > 1 ? "(Qty:" + inventory.quantity + ")" : ""
            }${imageIndex > 0 ? "_" + (imageIndex + 1) : ""}${
              image?.is_gps && image?.gps_photo_data_url
                ? image?.gps_photo_data_url?.match(/\.\w+$/)[0]
                : image?.data_url?.match(/\.\w+$/)[0]
            }`,
            creative_name: inventory?.creative_name,
          });
        } catch (e) {
          // ignore
        }
      });
    });

  let imagePromises = urls.map((x) => getImageBlobData(x.name, x.url));
  let creativeList = urls.map((x) => ({
    ...x,
    creative_name: x.creative_name,
  }));

  let count = 0;
  Promise.all(imagePromises).then((results) => {
    let img = "";
    results.forEach((x) => {
      if (creativeList[count]?.creative_name !== "") {
        img = zip.folder(creativeList[count]?.creative_name);
        img.file(x.name, x.data, { base64: true });
      } else {
        zip.file(x.name, x.data, { base64: true });
      }
      count++;
    });
    zip.generateAsync({ type: "blob" }).then((buffer) => {
      let blob = new Blob([buffer], { type: "application/zip" });
      let a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.style.display = "none";
      a.download = configuration.exportName ?? "plan.zip";
      document.body.appendChild(a);
      a.click();
      callBack(true);
    });
  });
};
