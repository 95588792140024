// Provides functional interface for admin api

import cache from "./cache";
import { jwtDecode as jwt_decode } from "jwt-decode";

const apiUrl = "https://iam.ooh.systems";

const executeApi = async (method, url, body, skipCredentials = false) => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (!skipCredentials) {
    headers["Authorization"] = `Bearer ${await AdminApi.getAuthToken()}`;
  }
  const response = await fetch(apiUrl + url, {
    method: method.toUpperCase(),
    headers: headers,
    body: body ? JSON.stringify(body) : null,
  });

  let bodyText = await response.text();
  try {
    return JSON.parse(bodyText);
  } catch (e) {
    if (bodyText === undefined || bodyText.trim() === "") {
      return "";
    }
    throw bodyText;
  }
};

const urls = {
  login: "/auth/login",
  token: "/auth/token",
  validateToken: "/auth/is_valid",
  activateAccount: "/auth/activate",
  changePasswordRequest: "/auth/password/change",
  forgotPasswordRequest: "/auth/password/forgot",
  forgotPasswordVerifyOtp: "/auth/password/forgot/verify",
  getRoles: "/iam/roles",
  getUserDetails: "/user/{id}",
  queryUsers: "/user/query",
  toggleUserActivation: "/user/{id}/status/{status}",
  toggleUserRole: "/user/roles?action={action}&id={id}&role={role}",
  syncUserSettings: "/user/settings/sync",
  getUserSession: "/user/sessions",
  removeSession: "/user/sessions/{id}",
  getOtpForEmailUpdate: "/user/update/email/otp",
  updateUserEmail: "/user/update/email",
  getOtpFormPhoneUpdate: "/user/update/phone/otp",
  updateUserPhone: "/user/update/phone",
  updateUserDetails: "/user/update",
  setProfilePicture: "/user/picture",
  createUser: "/user/create",
  userQuota: "/customer/quota/user"
};

const Roles = {
  Admin: "ad",
  Employee: "sr",
  SuperAdmin: "su",
  BranchManager: "bm",
  Mounter: "mo",
  Photographer: "ph",
  Accounts: "ac",
  PurchaseManager: "pm",
  PhotoReviewer: "pr",
  Planner: "pl",
  PurchaseAdmin: "pa",
  PurchaseTransactions: "pt"
};

const AdminApi = {
  url: apiUrl,
  roles: Roles,
  storeLoginToken: (tokenData) => {
    cache.store("token", tokenData);
  },
  login: async (id, password) => {
    return executeApi(
      "POST",
      urls.login,
      {
        id: id,
        password: password,
      },
      true
    );
  },
  isLoggedIn: async () => {
    let token = cache.get("token");
    return token;
  },
  validateToken: async() => {
    return await executeApi("GET", urls.validateToken);
  },
  getCurrentUser: () => {
    let token = cache.get("token");
    if (!token) return null;
    let decodedToken = jwt_decode(token.token);
    return decodedToken.user;
  },
  getUserDetails: async (id) => {
    return await executeApi("GET", urls.getUserDetails.replace("{id}", id));
  },
  updateUser: async (userDetails) => {
    return await executeApi("POST", urls.updateUserDetails, userDetails);
  },
  updateUserCache: async (id) => {
    cache.remove(`user:${id}`);
    let user = await AdminApi.getUserDetails(id);
    if (user) {
      cache.store(`user:${id}`, user, new Date().getTime() + 15 * 60 * 1000);
    }
  },
  getCachedUser: async (id) => {
    let cacheUser = cache.get(`user:${id}`);
    if (cacheUser) return cacheUser;
    cacheUser = await AdminApi.getUserDetails(id);
    cache.store(`user:${id}`, cacheUser, new Date().getTime() + 15 * 60 * 1000);
    return cacheUser;
  },
  renewAuthToken: async () => {
    // 1. check if local token is available
    let tokenData = cache.get("token");
    if (!tokenData) throw "Token not available";

    // 2. check if local refresh token is valid
    let refreshToken = jwt_decode(tokenData.refreshToken);

    if (refreshToken.exp > Math.floor(new Date().getTime() / 1000)) {
      let res = await executeApi(
        "POST",
        urls.token,
        {
          refreshToken: tokenData.refreshToken,
        },
        true
      );
      AdminApi.storeLoginToken({
        refreshToken: tokenData.refreshToken,
        token: res.token,
      });
      return res.token;
    }

    cache.clear();
    window.location.reload();
  },
  // Get auth token for authorization header
  getAuthToken: async () => {
    // 1. check if local token is valid
    let localTokenData = cache.get("token");
    let localToken = undefined;
    try {
      localToken = jwt_decode(localTokenData.token);
    } catch (e) {}
    // 2. check if refresh token is valid
    if (
      !localToken ||
      Math.floor(new Date().getTime() / 1000) > localToken.exp
    ) {
      // 3. renew token if needed
      return await AdminApi.renewAuthToken();
    } else {
      return localTokenData.token;
    }
  },
  queryUsers: async (q = "", page = 0, length = 99) => {
    return await executeApi(
      "GET",
      `${urls.queryUsers}?q=${q}&page=${page}&length=${length}`
    );
  },
  getUserQuota: async () => {
    return await executeApi("GET", `${urls.userQuota}`) 
  },
  addRole: async (id, role) => {
    return await executeApi(
      "GET",
      urls.toggleUserRole
        .replace("{action}", "add")
        .replace("{role}", role)
        .replace("{id}", id)
    );
  },
  removeRole: async (id, role) => {
    return await executeApi(
      "GET",
      urls.toggleUserRole
        .replace("{action}", "remove")
        .replace("{role}", role)
        .replace("{id}", id)
    );
  },
  setRoles: async (id, roles) => {
    return await executeApi(
      "GET",
      urls.toggleUserRole
        .replace("{action}", "set")
        .replace("{role}", roles.join(","))
        .replace("{id}", id)
    );
  },
  activateUser: async (id) => {
    return await executeApi(
      "GET",
      urls.toggleUserActivation
        .replace("{id}", id)
        .replace("{status}", "activate")
    );
  },
  deactivateUser: async (id) => {
    return await executeApi(
      "GET",
      urls.toggleUserActivation
        .replace("{id}", id)
        .replace("{status}", "deactivate")
    );
  },
  createUser: async (userData) => {
    return await executeApi("POST", urls.createUser, userData);
  },
  activateAccount: async (userName, otp, password) => {
    return await executeApi(
      "POST",
      urls.activateAccount,
      {
        id: userName,
        otp: otp,
        password: password,
      },
      true
    );
  },
  requestForgotPassword: async (identity, mode) => {
    return await executeApi(
      "POST",
      urls.forgotPasswordRequest,
      {
        mode: mode,
        id: identity,
      },
      true
    );
  },
  forgotPassword: async (identity, otp, newPassword) => {
    return await executeApi(
      "POST",
      urls.forgotPasswordVerifyOtp,
      {
        id: identity,
        otp: otp,
        newPassword: newPassword,
      },
      true
    );
  },
  getOtpForEmailUpdate: async (email) => {
    return await executeApi("POST", urls.getOtpForEmailUpdate, {
      email: email,
    });
  },
  getOtpFormPhoneUpdate: async (phoneNumber) => {
    return await executeApi("POST", urls.getOtpFormPhoneUpdate, {
      phoneNumber: phoneNumber,
    });
  },
  updateUserEmail: async (email, otp) => {
    return await executeApi("POST", urls.updateUserEmail, {
      email: email,
      otp: otp,
    });
  },
  updateUserPhoneNumber: async (phoneNumber, otp) => {
    return await executeApi("POST", urls.updateUserPhone, {
      phoneNumber: phoneNumber,
      otp: otp,
    });
  },
  setProfilePicture: async (extension, contentType, data) => {
    let userSettings = await executeApi("POST", urls.setProfilePicture, {
      dataExtension: extension,
      contentType: contentType,
      data: data,
    });
    localStorage.setItem("configuration", userSettings);
    return userSettings;
  },
  syncUserSettings: async (settings) => {
    return await executeApi("POST", urls.syncUserSettings, {
      data: settings,
      updatedDate: new Date(),
    });
  },
  getUserSettings: async () => {
    let localSettings = localStorage.getItem("configuration");
    if (localSettings) {
      return JSON.parse(localSettings);
    } else {
      let userSettings = await AdminApi.syncUserSettings({
        data: {},
        updatedDate: new Date(1999, 1, 1),
      });
      localStorage.setItem("configuration", JSON.stringify(userSettings));
      return userSettings;
    }
  },
  syncColumsSettings: async (settings) => {
    return await executeApi("POST", urls.syncUserSettings, {
      ...settings,
    });
  },
};

export default AdminApi;
