import React from "react";
import { Row, Col, Table, Button } from "antd";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";

import { currencyFormatter } from "../../components/common/common-formatter";

class SalesMonthlyList extends CommonListBase {
  state = {
    listReq:
      "dashboard/sales?resultgroup=month&durationGroup=" +
      this?.props?.year +
      "&",
    delReq: "sistercompany/delete?payout_estimate_id=",
    dtlReq: "sistercompany",
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "dashboard-city-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  // Setting Columns
  getColumns = (type) => {
    let columns = [
      {
        title: type,
        dataIndex: "type",
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("apr")}
            >
              April {this.getYear()}
            </div>
          );
        },
        dataIndex: "apr",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("may")}
            >
              May {this.getYear()}
            </div>
          );
        },
        dataIndex: "may",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jun")}
            >
              June {this.getYear()}
            </div>
          );
        },
        dataIndex: "jun",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jul")}
            >
              July {this.getYear()}
            </div>
          );
        },
        dataIndex: "jul",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("aug")}
            >
              August {this.getYear()}
            </div>
          );
        },
        dataIndex: "aug",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("sep")}
            >
              September {this.getYear()}
            </div>
          );
        },
        dataIndex: "sep",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("oct")}
            >
              October {this.getYear()}
            </div>
          );
        },
        dataIndex: "oct",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("nov")}
            >
              November {this.getYear()}
            </div>
          );
        },
        dataIndex: "nov",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("dec")}
            >
              December {this.getYear()}
            </div>
          );
        },
        dataIndex: "dec",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jan")}
            >
              January {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "jan",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("feb")}
            >
              February {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "feb",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("mar")}
            >
              March {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "mar",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      // {
      //   title: () => {
      //     return (
      //       <div
      //         className="link-hdr"
      //         onClick={() => this.handleMonthClick("total")}
      //       >
      //         Average / Month
      //       </div>
      //     );
      //   },
      //   dataIndex: "average_sales",
      //   render: (text) => currencyFormatter(parseInt(text)),
      // },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("total")}
            >
              Total
            </div>
          );
        },
        dataIndex: "total",
        render: (text) => currencyFormatter(parseInt(text)),
      },
    ];

    return columns;
  };

  // Data Formatting
  dataFormating = (data) => {
    const output = [
      {
        id: "1",
        type: "Realised (billed)",
        total: 0,
      },
      {
        id: "2",
        type: "Unrealised (unbilled)",
        total: 0,
      },
      {
        id: "3",
        type: "Total",
        total: 0,
      },
    ];

    data.forEach(
      ({ month_name, total_real_sales, total_unreal_sales, total_sales }) => {
        const monthKey = month_name.toLowerCase();

        output[0][monthKey] = total_real_sales.toFixed(2);
        output[0].total += total_real_sales;

        output[1][monthKey] = total_unreal_sales.toFixed(2);
        output[1].total += total_unreal_sales;

        output[2][monthKey] = total_sales.toFixed(2);
        output[2].total += total_sales;
      }
    );

    output[0].total = output[0].total.toFixed(2);
    output[1].total = output[1].total.toFixed(2);
    output[2].total = output[2].total.toFixed(2);

    return output;
  };

  getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const financialYear = now.getMonth() >= 3 ? year : year - 1;

    let displayYear = financialYear.toString().slice(-2);

    if (this?.props?.year !== "current_finyear") {
      displayYear = displayYear - 1;
    }

    return displayYear;
  };

  handleMonthClick = (month) => {
    this.props.handleDetails(month);
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
  }

  render() {
    const { data, pagination, loading } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <Table
              columns={this.getColumns("Sales Type")}
              rowKey={(record) => record?.id}
              dataSource={this.dataFormating(data)}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesMonthlyList;
