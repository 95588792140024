import React from "react";
import { Row, Col, Table, Button } from "antd";
import CommonListBase from "../../base/common-list-base";
import ListFiltersDashboard from "../common/list-filters-dashboard";
import { currencyFormatter } from "../../components/common/common-formatter";

class SalesEstimateList extends CommonListBase {
  state = {
    listReq:
      "dashboard/sales?resultgroup=payoutestimate&durationGroup=" +
      this?.props?.year +
      "&",
    delReq: "sistercompany/delete?payout_estimate_id=",
    dtlReq: "sistercompany",
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "dashboard-estimate-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  setColumns = () => {
    let columns = [
      {
        title: "Payout Estimate Name",
        dataIndex: "type",
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("apr")}
            >
              April {this.getYear()}
            </div>
          );
        },
        dataIndex: "apr",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("apr", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("may")}
            >
              May {this.getYear()}
            </div>
          );
        },
        dataIndex: "may",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("may", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jun")}
            >
              June {this.getYear()}
            </div>
          );
        },
        dataIndex: "jun",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("jun", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jul")}
            >
              July {this.getYear()}
            </div>
          );
        },
        dataIndex: "jul",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("jul", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("aug")}
            >
              August {this.getYear()}
            </div>
          );
        },
        dataIndex: "aug",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("aug", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("sep")}
            >
              September {this.getYear()}
            </div>
          );
        },
        dataIndex: "sep",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("sep", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("oct")}
            >
              October {this.getYear()}
            </div>
          );
        },
        dataIndex: "oct",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("oct", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("nov")}
            >
              November {this.getYear()}
            </div>
          );
        },
        dataIndex: "nov",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("nov", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("dec")}
            >
              December {this.getYear()}
            </div>
          );
        },
        dataIndex: "dec",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("dec", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("jan")}
            >
              January {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "jan",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("jan", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("feb")}
            >
              February {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "feb",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("feb", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("mar")}
            >
              March {Number(this.getYear()) + 1}
            </div>
          );
        },
        dataIndex: "mar",
        render: (text, record) => (
          <div
            className="link-value"
            onClick={() => this.handleMonthClick("mar", record)}
          >
            {currencyFormatter(parseInt(text))}
          </div>
        ),
      },
      // {
      //   title: () => {
      //     return (
      //       <div
      //         className="link-hdr"
      //         onClick={() => this.handleMonthClick("total")}
      //       >
      //         Average / Month
      //       </div>
      //     );
      //   },
      //   dataIndex: "average_sales",
      //   render: (text) => currencyFormatter(parseInt(text)),
      // },
      {
        title: () => {
          return (
            <div
              className="link-hdr"
              onClick={() => this.handleMonthClick("total")}
            >
              Total
            </div>
          );
        },
        dataIndex: "total",
        render: (text) => currencyFormatter(parseInt(text)),
      },
    ];

    const filterColumns = [
      {
        columnName: "payout_estimate_name",
        displayName: "Payout Estimate Name",
        default: true,
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  dataFormating = (data) => {
    let newD = data.map((x, key) => {
      const monthlySales = {};
      x?.monthly_sales?.forEach((y) => {
        monthlySales[y.month] = y.total_sales;
      });

      const totalSales = Object.values(monthlySales).reduce(
        (sum, sales) => sum + (sales || 0),
        0
      );

      return {
        id: key,
        type: x?.payout_estimate_name,
        payout_estimate_id: x?.payout_estimate_id,
        apr: monthlySales[4],
        may: monthlySales[5],
        jun: monthlySales[6],
        jul: monthlySales[7],
        aug: monthlySales[8],
        sep: monthlySales[9],
        oct: monthlySales[10],
        nov: monthlySales[11],
        dec: monthlySales[12],
        jan: monthlySales[1],
        feb: monthlySales[2],
        mar: monthlySales[3],
        average_sales: x?.average_sales,
        total: totalSales,
      };
    });

    return newD;
  };

  getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const financialYear = now.getMonth() >= 3 ? year : year - 1;

    let displayYear = financialYear.toString().slice(-2);

    if (this?.props?.year !== "current_finyear") {
      displayYear = displayYear - 1;
    }

    return displayYear;
  };

  handleMonthClick = (month, record) => {
    this.props.handleDetails(month, record);
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <ListFiltersDashboard
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={this.dataFormating(data)}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesEstimateList;
