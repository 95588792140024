import React, { Component } from "react";
import {
  Form,
  Checkbox,
  Space,
  Select,
  Row,
  Col,
  Input,
  Button,
  message,
} from "antd";
import { ajaxRequester } from "../../services/ajax-requester";

class CampaignNotifications extends Component {
  state = {};

  //   Get Notification List from Lookup
  getNotificationList = () => {
    let reqUrl = "lookup/emailnotifications";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ emailnotificationsList: data.response });
      },
      "GET"
    );
  };

  // Get Sagment List from Lookup
  getSegmentList = () => {
    let reqUrl = "lookup/segment";
    let reqObj = "";

    ajaxRequester(
      reqUrl,
      reqObj,
      (data) => {
        this.setState({ segmentList: data.response });
      },
      "GET"
    );
  };

  onFinish = (values) => {
    let reqUrl = "campaign/update/emailnotifications";
    let reqObj = {
      request: {
        campaign_id: this.props.results.campaign_id,
        segment_id: values.segment_id ? values.segment_id : undefined,
        notes: values.notes,
        email_notifications: this.getEmailNotifications(values),
      },
    };

    ajaxRequester(reqUrl, reqObj, () => {
      message.success("Campaign updated successfully");
      this.props.handleModal();
    });
  };

  // Get Notification Request
  getEmailNotifications = (data) => {
    let email_notifications = [
      {
        id: 1,
        code: "BER",
        value: data.email_notifications[0]["BERInput"],
        selected: data.email_notifications[0]["BER"],
      },
      {
        id: 2,
        code: "MMUP",
        value: data.email_notifications[0]["MMUPInput"],
        selected: data.email_notifications[0]["MMUP"],
      },
      {
        id: 3,
        code: "CER",
        value: data.email_notifications[0]["CERInput"],
        selected: data.email_notifications[0]["CER"],
      },
      {
        id: 4,
        code: "SIC",
        value: "",
        selected: data.email_notifications[0]["SIC"],
      },
      {
        id: 5,
        code: "SCNC",
        value: "",
        selected: data.email_notifications[0]["SCNC"],
      },
      {
        id: 6,
        code: "SOR",
        value: data.email_notifications[0]["SORInput"],
        selected: data.email_notifications[0]["SOR"],
      },
      {
        id: 7,
        code: "SPR",
        value: "",
        selected: data.email_notifications[0]["SPR"],
      },
      {
        id: 8,
        code: "GACR",
        value: data.email_notifications[0]["GACRInput"],
        selected: data.email_notifications[0]["GACR"],
      },
    ];

    return email_notifications;
  };

  componentDidMount() {
    this.getNotificationList();
    this.getSegmentList();
  }

  render() {
    const { Option } = Select;
    const { TextArea } = Input;

    const layout = {
      wrapperCol: { span: 24 },
    };

    const getNotification = (code) => {
      return (
        this.state.emailnotificationsList &&
        this.state.emailnotificationsList.find(
          (x) => x.notification_code === code
        ).notification
      );
    };

    const getSelected = (code) => {
      return this.props.results.email_notifications
        ? this.props.results.email_notifications.find((x) => x.code === code)
            .selected
        : true;
    };

    const getSelectedValue = (code) => {
      return this.props.results.email_notifications
        ? this.props.results.email_notifications.find((x) => x.code === code)
            .value
        : "";
    };

    const notes = this.props?.results?.notes ? this.props.results.notes : "";
    const segment_id = this.props?.results?.segment_id
      ? this.props.results.segment_id
      : "";

    return (
      <Form
        name="campaignNotificationsForm"
        {...layout}
        onFinish={this.onFinish}
        initialValues={{
          email_notifications: [""],
        }}
        className="campaign-notifications"
      >
        <div style={{ display: "none" }}>
          <Form.List name="email_notifications">
            {(fields) => (
              <div className="campaign-notifications-list">
                {fields.map((field) => (
                  <Space key={field.key}>
                    <Row>
                      <Col span="16">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "BER"]}
                          fieldKey={[field.fieldKey, "BER"]}
                          valuePropName="checked"
                          initialValue={getSelected("BER")}
                        >
                          <Checkbox>{getNotification("BER")}</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "BERInput"]}
                          fieldKey={[field.fieldKey, "BERInput"]}
                          initialValue={getSelectedValue("BER")}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder="0, 2, 5 Hours"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span="16">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "MMUP"]}
                          fieldKey={[field.fieldKey, "MMUP"]}
                          valuePropName="checked"
                          initialValue={getSelected("MMUP")}
                        >
                          <Checkbox>{getNotification("MMUP")}</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "MMUPInput"]}
                          fieldKey={[field.fieldKey, "MMUPInput"]}
                          initialValue={getSelectedValue("MMUP") || "onEvent"}
                        >
                          <Select placeholder="Select">
                            <Option value="onEvent">onEvent</Option>
                            <Option value="Compilation">Compilation</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span="16">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "CER"]}
                          fieldKey={[field.fieldKey, "CER"]}
                          valuePropName="checked"
                          initialValue={getSelected("CER")}
                        >
                          <Checkbox>{getNotification("CER")}</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "CERInput"]}
                          fieldKey={[field.fieldKey, "CERInput"]}
                          initialValue={getSelectedValue("CER")}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder="0, 3, 5, 7 Days"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span="16">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "SOR"]}
                          fieldKey={[field.fieldKey, "SOR"]}
                          valuePropName="checked"
                          initialValue={getSelected("SOR")}
                        >
                          <Checkbox>{getNotification("SOR")}</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "SORInput"]}
                          fieldKey={[field.fieldKey, "SORInput"]}
                          initialValue={getSelectedValue("SOR") || "Monthly"}
                        >
                          <Select>
                            <Option value="Monthly">Monthly</Option>
                            <Option value="Weekly">Weekly</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span="16">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "GACR"]}
                          fieldKey={[field.fieldKey, "GACR"]}
                          valuePropName="checked"
                          initialValue={getSelected("GACR")}
                        >
                          <Checkbox>{getNotification("GACR")}</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "GACRInput"]}
                          fieldKey={[field.fieldKey, "GACRInput"]}
                          initialValue={getSelectedValue("GACR")}
                        >
                          <Input
                            style={{ width: "100%" }}
                            placeholder="Before 7, 16 Days"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter="16">
                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "SIC"]}
                          fieldKey={[field.fieldKey, "SIC"]}
                          valuePropName="checked"
                          initialValue={getSelected("SIC")}
                        >
                          <Checkbox>{getNotification("SIC")}</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "SCNC"]}
                          fieldKey={[field.fieldKey, "SCNC"]}
                          valuePropName="checked"
                          initialValue={getSelected("SCNC")}
                        >
                          <Checkbox>{getNotification("SCNC")}</Checkbox>
                        </Form.Item>
                      </Col>

                      <Col span="8">
                        <Form.Item
                          {...field}
                          {...layout}
                          name={[field.name, "SPR"]}
                          fieldKey={[field.fieldKey, "SPR"]}
                          valuePropName="checked"
                          initialValue={getSelected("SPR")}
                        >
                          <Checkbox>{getNotification("SPR")}</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))}
              </div>
            )}
          </Form.List>
        </div>

        <Form.Item
          label="Select Segment"
          name="segment_id"
          initialValue={segment_id}
        >
          {this.state.segmentList && (
            <Select placeholder="Select Segment" allowClear>
              {this.state.segmentList.map((x) => (
                <Option key={x.segment_id} value={x.segment_id}>
                  {x.segment}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Campaign Notes" name="notes" initialValue={notes}>
          <TextArea placeholder="Campaign Notes" />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    );
  }
}

export default CampaignNotifications;
