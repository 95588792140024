import React from "react";
import { Button, Switch } from "antd";
import { RolesPermissions } from "../../helpers/roles-permissions";
import {
  CheckOutlined,
  UserAddOutlined,
  PlusOutlined,
  StopOutlined,
  RotateLeftOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";

const OperationNav = (props) => {
  const { page, selectedRowKeys } = props;
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="operation-nav">
      {page === "waiting-for-flex" && (
        <React.Fragment>
          <Switch
            size="small"
            onChange={(e) => props.handleSwitch(Boolean(e))}
          ></Switch>
          <label>
            <span className="mobile-hide">Flex Received </span>History
          </label>
          {(RolesPermissions("ad") || RolesPermissions("bm")) && (
            <Button
              disabled={!hasSelected}
              type="primary"
              onClick={() => props.handleAction(page, "mark-as-read")}
              icon={<CheckOutlined />}
              className="icon-btn"
            >
              Mark As Received
            </Button>
          )}
          {/* <Button
            disabled={!hasSelected}
            type="primary"
            onClick={() => props.handleAction(page, "setup-monitoring")}
          >
            Setup Monitoring
          </Button> */}
        </React.Fragment>
      )}

      {page === "change-of-site" && (
        <React.Fragment>
          <Switch
            size="small"
            onChange={(e) => props.handleSwitch(Boolean(e))}
          ></Switch>
          <label>
            <span className="mobile-hide">Change of Site </span>History List
          </label>
        </React.Fragment>
      )}

      {page === "pending-mounting" && (
        <React.Fragment>
          {(RolesPermissions("ad") ||
            RolesPermissions("bm") ||
            RolesPermissions("ph")) && (
            <React.Fragment>
              <Switch
                size="small"
                onChange={(e) => props.handleSwitch(Boolean(e))}
              ></Switch>

              <label>
                <span className="mobile-hide">Mounting </span>History
              </label>
            </React.Fragment>
          )}

          {(RolesPermissions("ad") || RolesPermissions("bm")) && (
            <React.Fragment>
              <Button
                type="primary"
                onClick={() => props.handleAction(page, "assign-mounter")}
                disabled={!hasSelected}
                icon={<UserAddOutlined />}
                className="icon-btn"
              >
                Assign Mounter
              </Button>
              <Button
                type="primary"
                onClick={() => props.handleAction(page, "add-mounting")}
                disabled={!hasSelected}
                icon={<PlusOutlined />}
                className="icon-btn"
              >
                Add Mounting
              </Button>
            </React.Fragment>
          )}
          {/* <Button
            disabled={!hasSelected}
            type="primary"
            onClick={() => props.handleAction(page, "mark-as-completed")}
          >
            Mark As Completed
          </Button> */}
        </React.Fragment>
      )}

      {page === "pending-unmounting-photo" && (
        <React.Fragment>
          {(RolesPermissions("ad") ||
            RolesPermissions("bm") ||
            RolesPermissions("ph")) && (
            <React.Fragment>
              <Switch
                size="small"
                onChange={(e) => props.handleSwitch(Boolean(e))}
              ></Switch>
              <label>
                <span className="mobile-hide">Unmounting Photo </span>History
              </label>
            </React.Fragment>
          )}

          {(RolesPermissions("ad") || RolesPermissions("bm")) && (
            <React.Fragment>
              <Button
                type="primary"
                onClick={() => props.handleAction(page, "assign-unmounter")}
                disabled={!hasSelected}
                icon={<UserAddOutlined />}
                className="icon-btn"
              >
                Assign Unmounter
              </Button>
              <Button
                type="primary"
                onClick={() => props.handleAction(page, "add-unmounting-photo")}
                disabled={!hasSelected}
                icon={<PlusOutlined />}
                className="icon-btn"
              >
                Add UnMounting Photo
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {page === "pending-unmounting" && (
        <React.Fragment>
          {(RolesPermissions("ad") ||
            RolesPermissions("bm") ||
            RolesPermissions("ph")) && (
            <React.Fragment>
              <Switch
                size="small"
                onChange={(e) => props.handleSwitch(Boolean(e))}
              ></Switch>
              <label>
                <span className="mobile-hide">Unmounting </span>History
              </label>
            </React.Fragment>
          )}
          {(RolesPermissions("ad") || RolesPermissions("bm")) && (
            <Button
              type="primary"
              onClick={() => props.handleAction(page, "receive-unmounted-flex")}
              disabled={!hasSelected}
              icon={<UserAddOutlined />}
              className="icon-btn"
            >
              Receive Unmounted Flex
            </Button>
          )}
        </React.Fragment>
      )}

      {page === "monitoring" && (
        <React.Fragment>
          {(RolesPermissions("ad") ||
            RolesPermissions("bm") ||
            RolesPermissions("ph")) && (
            <React.Fragment>
              <Switch
                size="small"
                onChange={(e) => props.handleSwitch(Boolean(e))}
              ></Switch>
              <label>
                <span className="mobile-hide">Monitoring </span>History
              </label>
            </React.Fragment>
          )}
          {(RolesPermissions("ad") ||
            RolesPermissions("bm") ||
            RolesPermissions("ph")) && (
            <Button
              type="primary"
              onClick={() => props.handleAction(page, "add-monitoring")}
              disabled={!hasSelected}
              icon={<PlusOutlined />}
              className="icon-btn"
            >
              Add Monitoring
            </Button>
          )}
        </React.Fragment>
      )}

      {page === "mounting-unmounting" && (
        <React.Fragment>
          <Switch
            size="small"
            onChange={(e) => props.handleSwitch(Boolean(e))}
          ></Switch>
          <label>
            <span className="mobile-hide">Advanced Mounting / Unmounting </span>
            History
          </label>

          {(RolesPermissions("ad") || RolesPermissions("bm")) && (
            <React.Fragment>
              <Button
                type="primary"
                onClick={() => props.handleAction(page, "assign-mounter")}
                disabled={!hasSelected}
                icon={<UserAddOutlined />}
                className="icon-btn"
              >
                Assign Mounter
              </Button>

              <Button
                type="primary"
                onClick={() => props.handleAction(page, "excel-export")}
                icon={<DownloadOutlined />}
                className="icon-btn"
              >
                Excel Export
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {page === "stock-management" && (
        <React.Fragment>
          {RolesPermissions("ad") && (
            <Button
              type="primary"
              onClick={() => props.handleAction(page, "sold")}
              disabled={!hasSelected}
              icon={<StopOutlined />}
              className="icon-btn"
            >
              Sold
            </Button>
          )}

          <Button
            type="primary"
            onClick={() => props.handleAction(page, "returned-to-clients")}
            disabled={!hasSelected}
            icon={<RotateLeftOutlined />}
            className="icon-btn"
          >
            Returned to Clients
          </Button>
        </React.Fragment>
      )}

      {page === "campaign-operations" && (
        <React.Fragment>
          {props?.activeTab === "mounting" && (
            <React.Fragment>
              <Button
                type="primary"
                onClick={() =>
                  props.handleAction(page, "add-additional-creative")
                }
                icon={<PlusOutlined />}
                className="icon-btn"
              >
                Add Additional Creative
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  props.handleAction(page, "additional-printing-mounting")
                }
                icon={<EditOutlined />}
                className="icon-btn"
              >
                Change Rates
              </Button>
            </React.Fragment>
          )}

          {props?.activeTab === "monitoring" && (
            <React.Fragment>
              <Button
                type="primary"
                onClick={() =>
                  props.handleAction(page, "add-campaign-monitoring")
                }
                icon={<PlusOutlined />}
                className="icon-btn"
              >
                Add Monitoring
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default OperationNav;
