import React, { Component } from "react";
import { Row, Col, Table, Button, Switch, Drawer, Checkbox } from "antd";
import {
  FileImageOutlined,
  FilterOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import PortalUser from "../../iam/components/portal-user";
import { ajaxRequester, getThumbUrl } from "../../services/ajax-requester";
import AdminApi from "../../iam/api/admin-api";
import PhotoGallery from "../../components/common/photo-gallery";
import InventoryAdvancedFilters from "../../components/inventories/inventory-advanced-filters";
import { exportPhotosToZip } from "../../components/photobucket/photos-to-zip";
import * as dayjs from "dayjs";

class PhotoBucket extends Component {
  state = {
    listReq: "photolibrary/list",
    delReq: "photolibrary/list",
    dtlReq: "photolibrary/list",
    data: [],
    gallery: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    record: "",
    selectedRowKeys: [],
    selectedRows: [],
    screen: "photobucket",
    defaultView: "gallery",
    startIndex: 0,
    isDownloading: false,
    selectedPhotos: [],
  };

  // Get Inventories List
  getResults = (params = {}) => {
    this.setState({ loading: true });
    let reqUrl = this.state.listReq;

    let reqObj = {
      request: this.getReqParams(params),
      // request: {},
    };

    ajaxRequester(reqUrl, reqObj, (data) => {
      this.setState(
        {
          loading: false,
          data: data.response,
          pagination: {
            ...params.pagination,
            total: data.page_info.total_records,
          },
          mediaTypeList: data.mediaTypeList,
          navKey: this.state.navKey + 1,
          filters: params.filters ? params.filters : this.state.filters,
          sortField: params.sortField ? params.sortField : this.state.sortField,
          sortOrder: params.sortOrder ? params.sortOrder : this.state.sortOrder,
          advancedFilters: reqObj.request,
        },
        () => this.callBack()
      );
    });
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Type",
        dataIndex: "operation_photo_type",
        render: (text) =>
          text === "M"
            ? "Mounting"
            : text === "U"
            ? "Unmounting"
            : "Monitoring",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "District",
        dataIndex: "district",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        render: (text, record) =>
          text +
          (record?.quantity && record?.quantity > 1
            ? " (Qty : " +
              record?.quantity +
              " / Display : " +
              (record?.side_type === "double"
                ? record?.quantity * 2
                : record?.quantity) +
              ")"
            : ""),
      },
      {
        title: "Width",
        dataIndex: "width",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Height",
        dataIndex: "height",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Employee",
        dataIndex: "employee_id",
        render: (text) => <PortalUser user={text} />,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 140,
      },
      {
        title: "Display Name",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photo",
        dataIndex: "opeartion_photos",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.opeartion_photos?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
    ];

    const filterColumns = [
      {
        columnName: "general",
        displayName: "General",
        default: true,
        hideFromSettings: true,
      },
      {
        columnName: "operation_photo_type",
        displayName: "Type",
        hideFilter: true,
      },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "district", displayName: "District" },
      { columnName: "city", displayName: "City" },
      { columnName: "location", displayName: "Location" },
      { columnName: "width", displayName: "Width" },
      { columnName: "height", displayName: "Height" },
      { columnName: "sqft", displayName: "SQFT" },
      { columnName: "employee_id", displayName: "Employee" },
      {
        columnName: "display_name",
        displayName: "Display Name",
        default: true,
      },
      { columnName: "images", displayName: "Photo" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Set Default Columns
  setDefaultColumns = (columns) => {
    AdminApi.syncColumsSettings()
      .then((columnsSettings) => {
        this.setState({ columnsSettings: columnsSettings || {} }, () =>
          this.applyDefaultColums(columns)
        );
      })
      .catch((nothing) => {});
  };

  // Apply Default Columns
  applyDefaultColums = (columns) => {
    let { columnsSettings, columnsDefault, screen } = this.state;
    if (screen && columnsSettings) {
      let colConfig = columnsSettings[screen];

      if (colConfig && colConfig.length > 1) {
        columns = columnsDefault.filter(({ title }) =>
          colConfig.some((exclude) => exclude.title === title)
        );
      }
      this.setState({ columns });
    }
  };

  // Getting Request Parameters
  getReqParams = (params) => {
    let reqParams = {};

    const {
      column_name,
      column_value,
      column_media_type,
      historical_purchase,
    } = this.state.appliedFilters || "";

    reqParams = {
      page_no: params.pagination.current,
      page_size: this.state?.isMapview ? 5000 : params.pagination.pageSize,
      column_name,
      column_value,
      column_media_type,
      sort_column: params.sortField,
      sort_order:
        params.sortOrder && (params.sortOrder === "ascend" ? "1" : "-1"),
      historical_purchase,
    };

    let advanced_filter = this.state.advanced_filter;
    if (advanced_filter) {
      reqParams = { ...reqParams, ...advanced_filter };
    }

    return reqParams;
  };

  // Change Pagging Sorting and Filters
  handleTableChange = (pagination, filters, sorter) => {
    this.getResults({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  // Handle Global Filters
  handleFilters = (filterColumn, filterValue) => {
    let { pagination } = this.state;
    pagination.current = 1;

    let appliedFilters = this.state.appliedFilters || {};

    if (filterValue) {
      appliedFilters.column_name = filterColumn.columnName;
      appliedFilters.column_value = filterValue;
    } else {
      appliedFilters.column_name = "";
      appliedFilters.column_value = "";
    }

    this.setState({ appliedFilters }, () => this.getResults({ pagination }));
  };

  // Handle Setting show hide columns
  handleSettings = (values) => {
    let columnsDefault = this.state.columnsDefault;
    let columns = this.state.columns;
    columns = columnsDefault.filter(({ title }) =>
      values.some((exclude) => exclude.title === title)
    );
    this.setState({ columns });
  };

  // Callback for update state
  callBack = () => {
    let { data } = this.state;
    let gallery = [];

    data &&
      data.length > 0 &&
      data.map((x) =>
        x?.opeartion_photos.map((y) =>
          gallery.push({
            ...x,
            photo: y,
            operation_media_id: y?.operation_media_id,
            created_date: y?.created_date,
          })
        )
      );

    gallery.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

    this.setState({ gallery });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  // Handle Closed Switch
  handleToggle = (e) => {
    this.setState({ defaultView: e ? "list" : "gallery" });
    this.getResults({
      pagination: {
        current: 1,
        pageSize: 20,
      },
    });
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    const { defaultView, gallery } = this.state;

    let photos = [];
    gallery?.map((x) =>
      photos.push({
        ...x.photo,
        imageTitle:
          (x?.inventory_code ? x?.inventory_code : "") +
          (x?.city ? " - " + x?.city : "") +
          (x?.location ? ", " + x?.location : "") +
          (x?.width ? " - " + (x?.width + "x" + x?.height) : "") +
          (x?.display_name ? " - " + x?.display_name : ""),
      })
    );

    let startIndex =
      gallery?.findIndex(
        (x) => x?.operation_media_id === record?.operation_media_id
      ) || 0;

    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record
        ? defaultView === "gallery"
          ? photos
          : record?.opeartion_photos
        : "",
      photosTitle: record ? record : "",
      startIndex: defaultView === "gallery" ? startIndex : 0,
    });
  };

  handleGalleryPagination = (add) => {
    let { pagination } = this.state;

    if (add) {
      pagination.current = pagination.current + 1;
    } else {
      pagination.current = pagination.current - 1;
    }

    this.getResults({ pagination });
  };

  // Handle Drawer
  handleDrawer = () => {
    this.setState({ isDrawer: !this.state.isDrawer });
  };

  handleAdvancedFilters = (data) => {
    let { pagination } = this.state;
    pagination.current = 1;

    this.setState(
      { isDrawer: !this.state.isDrawer, advanced_filter: data },
      () => this.getResults({ pagination })
    );
  };

  handleDownload = () => {
    this.setState({ isDownloading: true });

    const { defaultView, selectedRows, selectedPhotos, gallery } = this.state;

    let downloadPhotos = [];

    if (defaultView !== "gallery") {
      selectedRows.map((x) =>
        x?.opeartion_photos?.map((y) =>
          downloadPhotos.push({
            ...y,
            name:
              x?.inventory_code +
              " - " +
              x?.city +
              ", " +
              x?.location +
              " - " +
              x?.width +
              "x" +
              x?.height +
              " - " +
              x?.display_name +
              y?.data_url.match(/\.\w+$/)[0],
          })
        )
      );
    } else {
      let nameArr = [];
      selectedPhotos.map((x) =>
        nameArr.push(
          gallery.find((y) => x?.operation_media_id === y?.operation_media_id)
        )
      );

      downloadPhotos = selectedPhotos.map((x, key) => ({
        ...x,
        name:
          nameArr[key]?.inventory_code +
          " - " +
          nameArr[key]?.city +
          ", " +
          nameArr[key]?.location +
          " - " +
          nameArr[key]?.width +
          "x" +
          nameArr[key]?.height +
          " - " +
          nameArr[key]?.display_name +
          x?.data_url.match(/\.\w+$/)[0],
      }));
    }

    exportPhotosToZip(
      downloadPhotos,
      "Photos-" + dayjs().format("Do MMMM YYYY") + ".zip",
      () => {
        this.setState({ isDownloading: false });
      }
    );
  };

  onChangeCheckBox = (e, record) => {
    let { selectedPhotos } = this.state;

    if (e.target.checked) {
      selectedPhotos.push(record);
    } else {
      selectedPhotos = selectedPhotos.filter(
        (x) => x?.operation_media_id !== record?.operation_media_id
      );
    }

    this.setState({ selectedPhotos });
  };

  componentDidMount() {
    // Get List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      filterColumns,
      selectedRowKeys,
      isDrawer,
      screen,
      defaultView,
      isShowPhotos,
      photos,
      photosTitle,
      gallery,
      advancedFilters,
      startIndex,
      isDownloading,
      selectedPhotos,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      preserveSelectedRowKeys: true,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const hasSelected = selectedRowKeys.length > 0 || selectedPhotos.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle titleText="Photolibrary" />

            <div
              style={{
                top: "-64px",
                float: "right",
                marginBottom: "-44px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                height: "30px",
              }}
              class="title-btns title-btns-photobucket"
            >
              <Switch size="small" onChange={this.handleToggle}></Switch>
              <label style={{ marginLeft: "8px", marginRight: "16px" }}>
                List View
              </label>

              <Button
                type="primary"
                onClick={this.handleDrawer}
                style={{ marginLeft: "16px" }}
                icon={<FilterOutlined />}
                className="icon-btn"
              >
                Advanced Filters
              </Button>

              <Button
                type="primary"
                onClick={this.handleDownload}
                style={{ marginLeft: "16px" }}
                icon={<DownloadOutlined />}
                className="icon-btn"
                disabled={!hasSelected}
                loading={isDownloading}
              >
                Download
              </Button>
            </div>

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            {defaultView === "gallery" && (
              <React.Fragment>
                <div className="photobucket">
                  {gallery &&
                    gallery.length > 0 &&
                    gallery.map((x) => (
                      <div className="photobucket-col">
                        <Button
                          onClick={() => this.handlePhotos(x)}
                          className="photobucket-btn"
                        >
                          <img
                            className="photobucket-image"
                            src={getThumbUrl(
                              x?.photo?.is_gps && x?.photo?.gps_photo_thumb_url
                                ? x?.photo?.gps_photo_thumb_url
                                : x?.photo?.thumb_url
                            )}
                            alt=""
                            key={x?.photo?.operation_media_id}
                          />
                          <div className="photobucket-image-name">
                            {x?.inventory_code +
                              " - " +
                              x?.city +
                              ", " +
                              x?.location +
                              " - " +
                              x?.width +
                              "x" +
                              x?.height +
                              " - " +
                              x?.display_name}
                          </div>
                          <small className="photobucket-image-type">
                            {x.photo?.photo_category === "N"
                              ? "Newspaper"
                              : x.photo?.photo_category === "L"
                              ? "Long"
                              : "Short"}
                          </small>
                        </Button>
                        <div className="photobucket-checkbox">
                          <Checkbox
                            name="primary"
                            onChange={(e) => this.onChangeCheckBox(e, x?.photo)}
                            key={x?.photo?.operation_media_id}
                            checked={selectedPhotos.find(
                              (y) =>
                                y?.operation_media_id ===
                                x?.photo?.operation_media_id
                            )}
                          />
                        </div>
                      </div>
                    ))}
                </div>

                <div style={{ textAlign: "center", marginTop: "16px" }}>
                  <Button
                    style={{ marginRight: "8px" }}
                    type="primary"
                    onClick={() => this.handleGalleryPagination(false)}
                    disabled={pagination?.current <= 1 ? true : false}
                  >
                    Prev
                  </Button>

                  <Button
                    style={{ marginRight: "8px" }}
                    type="primary"
                    onClick={() => this.handleGalleryPagination(true)}
                    disabled={
                      pagination?.total / (20 * pagination?.current) <= 1
                        ? true
                        : false
                    }
                  >
                    Next
                  </Button>
                </div>
              </React.Fragment>
            )}

            {defaultView === "list" && (
              <Table
                columns={columns}
                rowKey={(record) =>
                  record?.operation_id + record?.operation_photo_type
                }
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
                bordered
                scroll={{ x: 400 }}
                size="small"
                rowSelection={rowSelection}
              />
            )}
          </Col>
        </Row>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
            startIndex={startIndex}
            photoBucket={defaultView === "gallery" ? true : false}
          />
        )}

        <Drawer
          title={"Advanced Filters"}
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width={"64%"}
        >
          <InventoryAdvancedFilters
            handleAdvancedFilters={this.handleAdvancedFilters}
            key={isDrawer}
            advancedFilters={advancedFilters}
            inventoryType={"HA"}
            photoBucket={true}
          />
        </Drawer>
      </React.Fragment>
    );
  }
}

export default PhotoBucket;
